import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Button
} from "@material-ui/core";
import { compose } from "recompose";
import Markdown from "../general/Markdown";
import { Image } from "../image/Image";
import Typo from "../general/Typo";
import { ServicesPageQuery_allContentfulService_edges_node } from "../../pages/__generated__/ServicesPageQuery";
import { get } from "../../helpers/data";
import { SpecificTheme } from "../../theme/default";
import { Link as GatsbyLink } from "gatsby";

const styles = (theme: SpecificTheme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.white,
      [theme.breakpoints.down("sm")]: {
        width: "90vw",
        marginTop: "5vh"
      },
      [theme.breakpoints.up("md")]: {
        width: "25vw",
        minHeight: "77vh",
        height: "100%"
      },
      [theme.breakpoints.only("xl")]: {
        width: "25vw"
      },
      padding: "10px"
    },
    bodyContainerStyles: {},
    innerContainer: {
      height: "100%",
      width: "100%",
      padding: "10px",
      backgroundColor: theme.specific.general.mediumPetrol
    },

    text: {},
    mainLayout: {
      color: theme.palette.common.white
    }
  });

type ServiceProps = {
  service: ServicesPageQuery_allContentfulService_edges_node;
  className?: string;
};

type ComponentProps = ServiceProps & WithStyles<typeof styles>;

const ServicePreview: React.FC<ComponentProps> = ({
  classes,
  className,
  service
}) => {
  const { header, subheader } = service;

  // const inlineTextStyles = classNames(classes.text);
  const introText = get(
    service,
    "intro",
    "childMarkdownRemark",
    "rawMarkdownBody"
  );
  // const imageFluid = get(service, "image", "fluid");

  const serviceHref = `/services/${service.slug}`;

  return (
    <div className={classes.root}>
      <GatsbyLink to={serviceHref}>
        <Image image={service.image} />
      </GatsbyLink>
      <Typo type="serviceSubheader">{subheader}</Typo>
      <Typo type="serviceHeader">{header}</Typo>

      <div className={classes.bodyContainerStyles}>
        <Markdown source={introText} />
        <Button variant="contained" color="primary" href={serviceHref}>
          Mehr
        </Button>
      </div>
    </div>
  );
};

export default compose<ComponentProps, ServiceProps>(withStyles(styles))(
  ServicePreview
);
