import React from "react";
import { createStyles, WithStyles, withStyles, Grid } from "@material-ui/core";
import { SpecificTheme } from "../theme/default";
import { compose } from "recompose";
import { graphql } from "gatsby";
import { ServicesPageQuery } from "./__generated__/ServicesPageQuery";
import { get } from "../helpers/data";
import { Container } from "../components/general";
import MainLayout from "../components/layout/MainLayout";
import { PageProps } from "../typings";
import Footer from "../components/general/Footer";
import SEO from "../components/SEO";
import ServicePreview from "../components/services/ServicePreview";
import { containerStyles } from "../components/general/Container";
import BackgroundService from "../components/services/BackgroundService";

type ServicePageProps = {} & PageProps<ServicesPageQuery> &
  WithStyles<typeof styles>;

const ServicePage: React.FC<ServicePageProps> = ({
  classes,
  data,
  location
}) => {
  const services = get(data, "allContentfulService", "edges");
  // const serviceBoxes = get(data, "allContentfulServiceBox", "edges");

  return (
    <MainLayout location={location} title="Services">
      <Container variant="standard" color="transparent">
        <BackgroundService>
          <Grid
            container
            spacing={0}
            justify="space-around"
            alignItems="stretch"
            className={classes.container}
          >
            {services &&
              services.map((edge, i) => {
                const service = edge.node;
                return (
                  <Grid key={service.header} item>
                    <ServicePreview key={service.header} service={service} />
                  </Grid>
                );
              })}
          </Grid>
        </BackgroundService>
      </Container>

      <Footer />
      {/* SEO, head params here */}
      <SEO
        general={{
          language: "de",
          contentType: "website",
          path: location.pathname,
          title: data.contentfulPages.seoTitle,
          description: data.contentfulPages.seoDescription
        }}
      />
    </MainLayout>
  );
};

const styles = (theme: SpecificTheme) => {
  const paddingStyles = containerStyles(theme).padding;
  return createStyles({
    container: {
      ...paddingStyles,
      [theme.breakpoints.down("sm")]: {
        minHeight: "85vh"
      },
      [theme.breakpoints.up("md")]: {
        minHeight: "82vh",
        paddingTop: "46px"
      }
    }
  });
};

export const servicesPageQuery = graphql`
  query ServicesPageQuery {
    contentfulPages(page: { eq: "Services" }) {
      seoTitle
      seoDescription
    }
    allContentfulService(
      sort: { fields: [order] }
      filter: { show: { eq: true } }
    ) {
      edges {
        node {
          slug
          header
          subheader
          intro {
            childMarkdownRemark {
              html
              rawMarkdownBody
            }
          }

          content {
            childMarkdownRemark {
              html
              rawMarkdownBody
            }
          }
          image {
            title
            description
            fluid(maxWidth: 1600) {
              ...ContentfulFluidImage
            }
          }
        }
      }
    }

    allContentfulServiceBox(sort: { fields: [order] }) {
      edges {
        node {
          header
          subheader
          lead {
            childMarkdownRemark {
              html
              rawMarkdownBody
            }
          }
          image {
            description
            fluid(maxWidth: 400) {
              ...ContentfulFluidImage
            }
          }
        }
      }
    }
  }
`;

export default compose<ServicePageProps, {}>(withStyles(styles))(ServicePage);
